import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { ChatBot } from './pages';
import { ToastContainer } from 'react-toastify';
import { Login, PrivateRoute } from './Auth';
import 'react-toastify/dist/ReactToastify.css';
import faveImage from "./assets/images/FULL-LOGO 1.svg";

function App() {
  useEffect(() => {
    // Set favicon and document title
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = faveImage;
    link.type = 'image/svg+xml';
    document.title = 'Enginia Assistant';
    document.head.appendChild(link);

    // Clean up the link element if the component is unmounted
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <ToastContainer />
        <Routes>
          {/* Public route */}
          <Route path="/login" element={<Login />} />
          
          {/* Protected route */}
          <Route path="/" element={
            <PrivateRoute>
              <ChatBot />
            </PrivateRoute>
          } />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
