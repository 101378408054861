import React, { useState } from 'react';
import './Auth.css';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import logo from "../assets/images/chatbot-logo.svg"

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  // const clearCookies = () => {
  //   const cookies = document.cookie.split(";");
  
  //   for (let cookie of cookies) {
  //     const eqPos = cookie.indexOf("=");
  //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
  //     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  //   }  
  // };
  // const handleLogout = async () => {
  //   try {
  //     // clearCookies(); 
  //     const response = await fetch(`https://erp.enginia.ae/api/method/logout`, {
  //       method: 'GET', 
  //       credentials: 'include', 
  //     });
  //     await response.json();
   
  //   } 
  //   catch (error) {
  //     // console.log("------------------------------------");
  //     // toast.error("Failed to fetch data. Please try again..");
  //   }
  //   clearCookies(); 
  // };

  const handleLogin = async (event) => {
    event.preventDefault();

    setUsernameError('');
    setPasswordError('');
    setLoginError('');

    if (!username.trim()) {
      setUsernameError('Username is required');
      return;
    }

    if (!password.trim()) {
      setPasswordError('Password is required');
      return;
    }

    try {
      setIsLoading(true); 

      const response = await axios.post(`https://erp.enginia.ae/api/method/login`, {
        usr: username,
        pwd: password,
      });

      if (response.status !== 200) {
        throw new Error('Login failed');
      }

      const { authentication, full_name } = response.data;

      // Store api_key, api_secret, in cookies
      Cookies.set('api_key', authentication.api_key);
      Cookies.set('api_secret', authentication.api_secret);
      Cookies.set('username', username);
      Cookies.set('full_name', full_name)


      // Redirect to the homepage
      window.location.href = '/';
    } catch (error) {
      setLoginError('Wrong username or password');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="auth">
      <div className="login-container">
        <Container>
          <div className='auth-title'>
            <img className='auth-img' src={logo} />
            <h1 className="login-title">Log in</h1>
            <p className="login-para">
              Log in to start chatting with our EnginiaBot!
            </p>
          </div>
          <form className="login-fields" onSubmit={handleLogin}>
            <input
              type="text"
              className={`login-field user-field ${usernameError && 'error'}`}
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            {usernameError && <p className="error-message">{usernameError}</p>}
            <input
              type="password"
              className={`login-field password-field ${passwordError && 'error'}`}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordError && <p className="error-message">{passwordError}</p>}
            {loginError && <p className="error-message">{loginError}</p>}
            <div className="forget">
            </div>
            <div className="login-btn">
              <input
                type="submit"
                value="Log in"
                className={`login-submit ${isLoading ? 'disabled' : ''}`}
                disabled={isLoading}
              />
            </div>
          </form>
          {/* <button onClick={handleLogout}></button> */}
         
        </Container>
      </div>
    </div>
  );
};

export default Login;
